function checkFooter() {
  $('footer').removeClass('stickyFooter');
  if ($('#mm-0').height() <= $(window).height()) {
    $('footer').addClass('stickyFooter');
    if ($(window).width() < 992) {
      if ($('.social-share').length) {
        $('footer').addClass('hasSocialShare');
      } else {
        $('footer').removeClass('hasSocialShare');
      }
    } else {
      $('footer').removeClass('hasSocialShare');
    }
  }
}

$(function () {
  checkFooter();
  $(window).resize(function () {
    checkFooter();
  });
  $(document).on('click', '.social-print', function () {
    window.print();
    return false;
  });
});

function closeLightbox() {
  $('.ekko-lightbox .close').trigger('click');
}

$(function () {
  if ($('.teaser-concorsi').length) {
    $('.teaser-concorsi').matchHeight();
  }
  $(document).on('click', 'a[data-toggle="lightbox"]', function (event) {
    event.preventDefault();
    $(this).ekkoLightbox({
      alwaysShowClose: false
    });
  });
});

function adjustHeaderMargin() {
  var $bannerTop = $('#banner-top');
  var $mainHeader = $('#main-header');
  if ($bannerTop.is(':visible')) {
    var bannerHeight = $bannerTop.outerHeight(true);
    if (bannerHeight && $mainHeader.length > 0) {
      $mainHeader.css('margin-top', bannerHeight);
      $mainHeader.next().css('margin-top', bannerHeight);
    }
  } else {
    if ($mainHeader.length > 0) {
      $mainHeader.css('margin-top', '');
      $mainHeader.next().css('margin-top', '');
    }
  }
}

$(document).ready(function () {
  adjustHeaderMargin();

  $(window).resize(function () {
    adjustHeaderMargin();
  });
});

function formatDate(date) {
  const days = ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"];
  const months = ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"];
  const d = new Date(date);
  if (isNaN(d)) return "";
  return `${days[d.getDay()]} ${d.getDate()} ${months[d.getMonth()]}`;
}

function isChristmasEveOrEndOfTheYear(date) {
  const d = new Date(date);
  return (d.getMonth() === 11 && (d.getDate() === 24 || d.getDate() === 31));
}

function shouldShowBanner(data, dataDaysBefore, dateCategory) {
  let congediDates = data.filter(item => item.titolo === dateCategory);
  congediDates.sort((a, b) => new Date(a.inizio) - new Date(b.inizio)); 
  
  let today = new Date();
  today.setHours(0, 0, 0, 0);

  for (const item of congediDates) {
    let startDate = new Date(item.inizio) > today ? new Date(item.inizio) : null;
    let endDate = new Date(item.fine) > today ? new Date(item.fine) : startDate;
    if (startDate && endDate) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);
      let startDateRange = new Date(startDate);
      startDateRange.setDate(startDate.getDate() - dataDaysBefore);
      if (today >= startDateRange && today <= endDate) {
        replaceRichTextWithDate(startDate, endDate);
        return true;
      }
    }
  }
  return false;
}

function replaceRichTextWithDate(startDate, endDate) {
  let $textContainer = $("#banner-top #text-container");
  if ($textContainer.length > 0) {
    const htmlStandardTextToUpdate = $('#banner-top').data('standard-message');
    const htmlChristmasTextToUpdate = $('#banner-top').data('christmas-message');
    let htmlTextToUpdate;
    if (isChristmasEveOrEndOfTheYear(startDate) || isChristmasEveOrEndOfTheYear(endDate)) {
      htmlTextToUpdate = htmlChristmasTextToUpdate;
      if (htmlTextToUpdate.includes("{}")) {
        let christmasEveDate = new Date(startDate.getFullYear(), startDate.getMonth(), 24);
        let endOfTheYearDate = new Date(startDate.getFullYear(), startDate.getMonth(), 31);
        htmlTextToUpdate = htmlTextToUpdate.replace("{}", `${formatDate(christmasEveDate)} e ${formatDate(endOfTheYearDate)}`);
      }
    } else {
      htmlTextToUpdate = htmlStandardTextToUpdate;
      if (htmlTextToUpdate.includes("{}")) {
        if (startDate.getTime() === endDate.getTime()) {
          htmlTextToUpdate = htmlTextToUpdate.replace("{}", `${formatDate(startDate)}`);
        } else {
          htmlTextToUpdate = htmlTextToUpdate.replace("{}", `da ${formatDate(startDate)} a ${formatDate(endDate)}`);
        }
        $textContainer.html(htmlTextToUpdate);
      }
    }
    $textContainer.html(htmlTextToUpdate);
  }
}

(async function () {
  async function fetchData() {
    try {
      const contextPath = $('#banner-top').data('context-path');
      const response = await fetch(`${contextPath}/dbutils/interruzioni-congedi`);
      return await response.json();
    } catch (error) {
      console.error('Error during the AJAX request:', error);
      return [];
    }
  }

  let showBanner = false;
  const data = await fetchData();
  const $bannerTop = $('#banner-top');
  if ($bannerTop.length > 0) {
    const dataDaysBefore = parseInt($('#banner-top').data('days-before'), 0);
    const alwaysShow = $('#banner-top').data('always-show');
    const dateCategory = $('#banner-top').data('date-category');

    showBanner = alwaysShow || shouldShowBanner(data, dataDaysBefore, dateCategory);
    if (showBanner) {
      $('#banner-top-wrapper').show();
      adjustHeaderMargin();
    }
  }
})();
